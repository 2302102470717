import React, {Component} from 'react';

export class RedirectMessageComponent extends Component {
  
    constructor(props) {
        super(props);

        const key = "html-item-" + Math.floor(Math.random() * 1000000);
        this.state = {
            html: '',
            key
        }
      }

      componentDidMount() {
        const { step } = this.props;
        const {inputAttributes} = step;
        let tHtml = inputAttributes.html;
        const timeOut = inputAttributes.timeout || 3000;
        let url = inputAttributes.url || 'https://lawnbot.biz';
        const target = inputAttributes.target || '_self';
    
        if ( typeof tHtml === 'function') {
          tHtml = tHtml();
        }

        if (typeof url === 'function') {
          url = url();
        }

        this.setState({html: tHtml, timeOut, url}, () => {
          setTimeout(() => {
            window.open(this.state.url, target);
          }, this.state.timeOut)
        });

       
      }

    render() {
      return (<p key={this.state.key} dangerouslySetInnerHTML={{__html: this.state.html}}></p>)
    }
  }